import * as $ from 'jquery';
import { BaseWidget } from './base-widget';

export class Dropdown extends BaseWidget {
    static NAME = 'dropdown';

    constructor(element) {
        super(element);

        this.dropdownId = this.element.data('dropdown');
        this.target = $(`[data-dropdown-target="${this.dropdownId}"]`);
    }

    init() {
        this.element.on('click', (e) => {
            e.preventDefault();

            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        });
        
        // if ('ontouchstart' in window || 'ontouch' in window ) {
        //     this.element.on('click', (e) => {
        //         e.preventDefault();
    
        //         if (this.isOpen) {
        //             this.close();
        //         } else {
        //             this.open();
        //         }
        //     });
        // }
    }

    open() {
        this.isOpen = true;
        this.element.addClass('is-open');
        this.target.addClass('is-open');
    }

    close() {
        this.isOpen = false;
        this.element.removeClass('is-open');
        this.target.removeClass('is-open');
    }
}
