import { BaseWidget } from './base-widget';

export class MediaViewer extends BaseWidget {
	static NAME = 'media-viewer';

	constructor(element) {
		super(element);
		this.modifier 			= this.element.data('media-viewer');
		this.activeItemIndex 	= 0;
		this.initialized 		= false;

		// Button
		this.button 			= $('[data-media-viewer-button]', 			    this.element);
		
		// Popover
		this.popover 			= $(`[data-media-viewer-popover="${this.modifier}"]`);
		this.popoverSlider 		= $('[data-media-viewer-popover-slider]', 		this.popover);
		this.popoverCounter 	= $('[data-media-viewer-popover-counter]', 		this.popover);
		this.popoverPrevArrow 	= $('[data-media-viewer-popover-arrow-prev]', 	this.popover);
		this.popoverNextArrow 	= $('[data-media-viewer-popover-arrow-next]', 	this.popover);
		this.popoverCloseButton = $('[data-media-viewer-popover-close]', 		this.popover);
		this.popoverMask 		= $('[data-media-viewer-popover-mask]', 		this.popover);
		this.popoverThumbnail 	= $('[data-media-viewer-thumbnail]', 			this.popover);
	}

	init() {
		this.addEventListeners();
	}

	addEventListeners() {
		this.button.on('click', (e) => {
			e.preventDefault();
			this.initPopover();
			console.log(this.button);
		});
	}

	initPopover() {
		if (this.initialized == false) {
			this.popoverSlider.slick({
				lazyLoad: 'ondemand',
				lazyLoad: 'progressive',
				infinite: false,
				cssEase: 'linear',
				slidesToShow: 1,
				swipeToSlide: true,
				draggable: true,
				focusOnSelect: true,
				arrows: true,
				slidesToScroll: 1,
				mobileFirst: true,
				dots: false,
				autoplay: false,
				adaptiveHeight: true,
				prevArrow: this.popoverPrevArrow,
				nextArrow: this.popoverNextArrow,
				centerPadding: 0,
			});


			this.initialized = true;
		}
		
		this.showPopup();

		this.popoverCloseButton.on('click', (e) => {
			e.preventDefault();
			this.hidePopup();
		});

		this.popoverMask.on('click', (e) => {
			e.preventDefault();
			this.hidePopup();
		});

		$(document).on('keyup.overlay', (e) => {
			if (e.which === 27 && !this.isOpen) {
				this.hidePopup();
			}
		});
	}

	showPopup() {
		this.popover.addClass('is-active');
		$('body').addClass('is-popover');
		this.popoverSlider.find('.slick-list').attr('tabindex', '1').focus();
	}

	hidePopup() {
		this.popover.removeClass('is-active');
		$('body').removeClass('is-popover');
	}
}
