import { BaseWidget } from './base-widget';

export class FeedbackCompany extends BaseWidget {
    static NAME = 'feedbackcompany';

    constructor(element) {
        super(element);            
    }

    init() {
        jQuery(document).ready(function ($) {
            // Reviews blok
            (function() {

              var feedbackAPI = "https://www.feedbackcompany.com/api/v2/oauth2/token";
              var token = '';
              $.getJSON( feedbackAPI, {
                    client_id: "B7A50A8200CF39CB87A4C9718BA89360",
                    client_secret: "59CA7458930BA73BF2A4305F7C49FC5E",
                    grant_type: "authorization_code"
                })
                .done(function( data ) {
                    token = data.access_token;

                    var feedbackAPI = "https://www.feedbackcompany.com/api/v2/review/summary/";

                    $.getJSON( feedbackAPI, {
                        access_token: data.access_token,
                        maxscore: 10
                    })
                    
                    .done(function( data ) {

                        var gemiddelde_score = '.c-review__bottom--average';
                        $(gemiddelde_score).replaceWith(data.statistics[5].value);
                          

                        var total_percentages = '.c-review-list__percentages';
                        $(total_percentages).replaceWith(data.statistics[4].endorsements.percentage +'%');
                        
                    });
                });
            })();

        });
    }
}