import { BaseWidget } from './base-widget';

export class InView extends BaseWidget {
    static NAME = 'in-view';

    constructor(element) {
        super(element);
    }

    init() {
        this.element.addClass('in-view');
        this.threshold = this.element.data('in-view');
        
        if (this.threshold === '') {
            this.threshold = .4
        }

        const config = {
            root: null, // sets the framing element to the viewport
            rootMargin: '0px',
            threshold: this.threshold
        };

        let observer = new IntersectionObserver(function(entries) {
            entries.forEach(function(item) {
                if (item.isIntersecting) {
                    item.target.classList.remove('is-out-view');
                    item.target.classList.add('is-in-view');
                }
            });
        }, config);
    
        if (this.element.offset().top > (window.scrollY + window.innerHeight)) {
            this.element.addClass('is-out-view');
            observer.observe(this.element[0]);
        } else {
            this.element.addClass('is-in-view');
        }
    }
}
