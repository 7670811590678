export class PopoverService {

    static spawnOverlay(html, args) {
        const defaults = {
            closeable: true,
            cssClass: false,
            onInsert: null,
            onClose: null
        };

        const options = $.extend({}, defaults, args);
        const overlay = this.createOverlay(html, options);

        requestAnimationFrame(() => {
            $('body').append(overlay);

            // Let people know
            document.dispatchEvent(new CustomEvent('bf-overlay-open'));

            requestAnimationFrame(() => {
                this.onInsert(overlay, options);
            });
        });
    }

    static onInsert(overlay, options) {
        overlay.addClass('is-active');

        if (options.closeable) {
            $('.popover__mask, .popover__close', overlay).on('click', (e) => {
                e.preventDefault();
                e.stopPropagation();

                this.closeOverlay(overlay, options);
            });

            $(document).on('keyup.overlay', (e) => {
                if (e.which === 27) {
                    this.closeOverlay(overlay, options);
                }
            });
        }

        if (options.onInsert) {
            options.onInsert(overlay);
        }
    }

    static createOverlay(html, options) {
        const overlay = $(
            '<div class="popover popover">' +
            '<div class="popover__mask"></div>' +
            '<div class="popover__content">' + html + '</div>' +
            '</div>'
        );

        if (options.cssClass) {
            overlay.addClass(options.cssClass);
        }

        if (options.closeable) {
            $('.popover__mask', overlay).after(
                `<button class="popover__close"><svg class="" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 32 32">
                <path d="M32 3.223L28.777 0 16 12.777 3.223 0 0 3.223 12.777 16 0 28.777 3.223 32 16 19.223 28.777 32 32 28.777 19.223 16z"/>
            </svg><span class="u-sr-only">Sluiten</span></button>`
            );
        }

        return overlay;
    }

    static closeOverlay(overlay, options) {
        if (options.onClose) {
            options.onClose(overlay);
        }

        requestAnimationFrame(() => {
            window.setTimeout(() => {
                overlay.removeClass('is-active');
            }, 100);

            requestAnimationFrame(() => {
                window.setTimeout(() => {
                    overlay.remove();
                }, 300);
            });
        });

        $(document).off('keyup.overlay');
    }
}
