import { throttle } from 'lodash';
import { BaseWidget } from './base-widget';

export class Reviews extends BaseWidget {
    static NAME = 'reviews';

    constructor(element) {
        super(element);            
        this.siteUrl = this.element.data('site');
        this.itemsArray = [];
    }

    init() {
        jQuery(document).ready(function ($) {
            // Review template
            (function() {

              var feedbackAPI = "https://www.feedbackcompany.com/api/v2/oauth2/token";
              var token = '';
              $.getJSON( feedbackAPI, {
                    client_id: "B7A50A8200CF39CB87A4C9718BA89360",
                    client_secret: "59CA7458930BA73BF2A4305F7C49FC5E",
                    grant_type: "authorization_code"
                })
                .done(function( data ) {
                    token = data.access_token;
                    var feedbackAPI = "https://www.feedbackcompany.com/api/v2/review";
                    

                    $.getJSON( feedbackAPI, {
                        access_token: data.access_token,
                        limit: 10,
                    })
                    
                    .done(function( data ) {

                        var total_count = '.c-review-list__total-count';
                        $(total_count).replaceWith(data.count.total);

                        var reviews = []
                    
                        var elements = $.each( data.reviews, function( i, item ) {
                            var review = []
                            
                            if (item.total_score > 4) {
                                review.push(item.client.gender, item.total_score, item.client.name);
                                

                                $.each( item.questions, function( i, question ) {
                                    if (question.question_id === "124243") {
                                        review.push(question.value);
                                    }
                                });

                                reviews.push(review);           

                                if ( i === 3 ) {
                                  return false;
                                }

                                localStorage.setItem('reviews', JSON.stringify(reviews));  

                            }
                        });

                        $.each( reviews, function( i, review ) {

                            if (review[1]) {

                                var stars = '.c-review-list__stars--' + i;
                                var starsHtml = '';
                                var s = 0;

                                for (s = 0; s < Math.trunc(review[1]); s++) {
                                     starsHtml = starsHtml + ' <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path fill="#EACB74" fill-rule="nonzero" d="m9.004 0 2.778 5.925 6.218.95-4.498 4.612L14.568 18l-5.564-3.075L3.442 18l1.056-6.513L0 6.875l6.218-.95z"></path></svg>'
                                }

                                // if ((review[1] - Math.trunc(review[1])) == '.5')
                                var half = review[1] - Math.trunc(review[1]);
                                if (half == '0.5') {
                                    starsHtml = starsHtml + ' <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><g fill-rule="nonzero" fill="none"><path d="m9.004 0 2.778 5.925 6.218.95-4.498 4.612L14.568 18l-5.564-3.075-.004.002V.006L9.004 0Z" fill="#E7E7E7"/><path d="M9 .006v14.921L3.442 18l1.056-6.513L0 6.875l6.218-.95L9 .006Z" fill="#EACB74"/></g></svg>'
                                }


                                $(stars).replaceWith(starsHtml);
                            }


                            if (review[2]) {

                                if (review[2].includes(' ')) {
                                    // Splits de volledige naam op in voornaam en achternaam
                                    var nameParts = review[2].split(' ');
                                    
                                    // Neem de eerste letter van de voornaam en achternaam
                                    var firstNameInitial = nameParts[0].charAt(0).toUpperCase();
                                    var lastNameInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();
                                    
                                    var avatar = '.c-review-list__avatar--inner--' + i;
                                 
                                    $(avatar).replaceWith(firstNameInitial + lastNameInitial);
                                } else {
                                    // Als er geen achternaam is, neem dan alleen de eerste letter van de voornaam
                                    var firstNameInitial = review[2].charAt(0).toUpperCase();
                               
                                    var avatar = '.c-review-list__avatar--inner--' + i;

                                    $(avatar).replaceWith(firstNameInitial);
                                }


                                var client = '.c-review-list__title--' + i;

                                $(client).replaceWith(review[2]);

                            }


                            if (review[3]) {

                                var text = '.c-review-list__review--' + i;

                                $(text).replaceWith(review[3]);

                            }

                        });

                        $('.c-review-list__item').removeClass('blur');
                    });
                });
            })();

        });
    }
}