import { BaseWidget } from './base-widget';

export class Accordion extends BaseWidget {
	static NAME = 'accordion';

	constructor(element) {
		super(element);
		this.triggers = $('[data-accordion-header]', this.element);
	}

	init() {
		$('.accordion__content').hide();

		this.element.on('click', (event) => {
			// $(event).next().slideToggle('slow');
			const item = $(event.target);
			item.parent().next('.accordion__content').slideToggle('slow');
			// console.log(event);
			// console.log(event.target.closest('.accordion__content'));
			// item.slideToggle('slow');
		});
	}
}
