import { Accordion } from './widgets/accordion';
import { Anchor } from './widgets/anchor';
import { Carousel } from './widgets/carousel';
import { Dropdown } from './widgets/dropdown';
import { FeedbackCompany } from './widgets/feedbackcompany';
import { GravityForms } from './widgets/gravity-forms';
import { Header } from './widgets/header';
import { Hover } from './widgets/hover';
import { InView } from './widgets/in-view';
import { Reviews } from './widgets/reviews';
import { ReviewsCarousel } from './widgets/reviews-carousel';
import { Map } from './widgets/map';
import { MediaViewer } from './widgets/media-viewer';
import { MenuOverlay } from './widgets/menu-overlay';
import { Lazyload } from './widgets/lazy-load';
import { ScrollTo } from './widgets/scroll-to';
import { Video } from './widgets/video';

// IE11 polyfill - If object-fit cover
const pfObjectFitImages = require('object-fit-images');

class App {
    constructor() {
        this.widgets = [];
        this.widgetMap = {
			[Accordion.NAME]: Accordion,
            [Anchor.NAME]: Anchor,
			[Carousel.NAME]: Carousel,
			// [Cursor.NAME]: Cursor,
            [Dropdown.NAME]: Dropdown,
            [FeedbackCompany.NAME]: FeedbackCompany,
            [GravityForms.NAME]: GravityForms,
            [Header.NAME]: Header,
            [Hover.NAME]: Hover,
            [InView.NAME]: InView,
            [Reviews.NAME]: Reviews,
            [ReviewsCarousel.NAME]: ReviewsCarousel,
            [Map.NAME]: Map,
            [MediaViewer.NAME]: MediaViewer,
			[MenuOverlay.NAME]: MenuOverlay,
            [Lazyload.NAME]: Lazyload,
            [ScrollTo.NAME]: ScrollTo,
            [Video.NAME]: Video,
		};

        this.callOut();
        this.externalLinks();
        this.addEventListeners();
    }

    initWidgets($container) {
        $container = $container || $(document);

        for (const key in this.widgetMap) {
            const elements = $container[0].querySelectorAll('[data-'.concat(key).concat(']'));

            for (const element of elements) {
                this.attachWidgetToElement(element, key);
            }
        }

        if (location.hostname === 'localhost') {
            console.log(this.widgets);
        };
    }

    attachWidgetToElement(element, key) {
        for (const widget of this.widgets) {
            if (widget.element === element) {
                return;
            }
        }

        const newWidget = new this.widgetMap[key]( $(element) );
        newWidget.init();

        this.widgets.push(newWidget);
    }

    clearWidgets($container) {
        $container = $container || $(document);

        for (const key in this.widgetMap) {
            const elements = $container[0].querySelectorAll('[data-'.concat(key).concat(']'));

            for (const element of elements) {
                this.detachWidgetsFromElement(element);
            }
        }

        console.log('Current widgets: ', this.widgets);
    }

    detachWidgetsFromElement(element) {
        const filteredWidgets = [];

        for (const widget of this.widgets) {
            if (widget.element !== element) {
                filteredWidgets.push(widget);
            }
        }

        this.widgets = filteredWidgets;
    }

    addEventListeners() {
        this.initWidgets();
    }

    // Add target _blank to external links
    externalLinks() {
        $('a[href^="http"]:not([href*="' + location.host + '"])')
            .attr('target', '_blank')
            .attr('rel', 'noopener')
        ;
	}

    callOut() {
        if (location.hostname !== 'localhost') {
            console.log(`%cDeze website is gerealiseerd door ` + `%cBrandfirm` + `%c\nhttps://www.brandfirm.nl/ - info@brandfirm.nl - +31(0)202170358`, `font-family:monospace;font-size:10px;`, `font-family:monospace;font-size:14px;color:#ff7525`, `font-family:monospace;font-size:10px;`);
        };
    }
}

document.addEventListener('DOMContentLoaded', () => {
    require('intersection-observer');
    
    new App();

    $( document ).ready(function() {
        $('body').addClass('loaded');
    })

    if ('ontouchstart' in window || 'ontouch' in window ) {
        $('body').addClass('touch');
    } else {
        $('body').addClass('no-touch');
    }
    
    pfObjectFitImages(); // IE11
});
