import { BaseWidget } from './base-widget';

export class MenuOverlay extends BaseWidget {
    static NAME = 'menu-overlay';

    constructor(element) {
        super(element);
        this.trigger = $('[data-menu-toggle]');
        this.closeButton = $('[data-menu-close]');
        this.content = $('[data-content]', this.element);
        this.closeOverlay = $('.c-site__overlay');
    }

    init() {
        this.trigger.on('click', (e) => {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        });

        this.closeOverlay.on('click', (e) => {
            if (this.isOpen) {
                this.close();
            }
        });

        this.closeButton.on('click', (e) => {
            if (this.isOpen) {
                this.close();
            }
        });

        $(".c-menu-overlay .c-menu__item--dropdown > .c-menu__chevron").on('click', function(e) {
            $(this).parent().find('.c-menu__dropdown').toggleClass("is-open");
            $(this).toggleClass("is-open");
        });

        // $(window).on('resize', () => {
        //     if (this.isOpen) {
        //         this.close();
        //     }
        // });
    }

    open() {
        this.isOpen = true;
        this.trigger.addClass('is-active');
        $('html').addClass('is-fixed');

        requestAnimationFrame(()  => {
            this.element.addClass('is-active');

            requestAnimationFrame(()  => {
                this.element.addClass('is-active');
                
                
            });
        });
    }

    close() {
        this.isOpen = false;
        $('html').removeClass('is-fixed');

        requestAnimationFrame(()  => {
            this.element.removeClass('is-active');

            requestAnimationFrame(()  => {
                this.trigger.removeClass('is-active');

                setTimeout((() => {
                    this.element.removeClass('is-active');
                }));
            });
        });
    }
}
