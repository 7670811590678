import { BaseWidget } from './base-widget';
import { Loader } from 'google-maps';
export class Map extends BaseWidget {
    static NAME = 'map';

    constructor(element) {
        super(element);
        this.lat = this.element.data('map-location-lat');
        this.lng = this.element.data('map-location-lng');
        this.title = this.element.data('map-title');
        this.api = this.element.data('map-api');
        this.themeUrl = this.element.data('theme-url');
    }

    init() {
        this.stopLoading();
        // this.loadGoogleMapsApi(); // TODO cleanup
        this.loadMap();
    }

    stopLoading() {
        const head = document.getElementsByTagName('head')[0];
        const insertBefore = head.insertBefore;

        head.insertBefore = function (newElement, referenceElement){
            if(newElement.href && newElement.href.indexOf('//fonts.googleapis.com/') > -1) { // Prevented Roboto font from loading
                return;
            }
        
            if(newElement.tagName.toLowerCase() === 'style' && newElement.innerHTML.indexOf('.gm-style') > -1){ // Intercept style elements for modern browsers
                return; // Prevented .gm-style from loading!
            }

            insertBefore.call(head, newElement, referenceElement);
        };
    }

    loadGoogleMapsApi() {        
        const options = {
            lat: this.lat,
            lng: this.lng,
            title: this.title,
            themeUrl: this.themeUrl,
        }

        const loader = new Loader(this.api);

        loader.load(options).then(function (google) {
            const map = new google.maps.Map(document.getElementById('map'), {
                center: {
                    lat: options.lat,
                    lng: options.lng
                },
                zoom: 13,
                disableDefaultUI: true,
                mapTypeControl: false,
                scaleControl: false,
                zoomControl: true,
                scrollwheel: false,
                fullscreenControl: true,
                rotateControl: false,
                tilt: 45,
            });

            const image = {
                url: options.themeUrl + '/dist/images/pointer.svg',
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(20, 28)
            };

            const marker = new google.maps.Marker({
                map: map,
                position: {
                    lat: options.lat,
                    lng: options.lng
                },
                title: options.title,
                icon: image
            });

            marker.setMap(map);
        });
    }

    loadMap() {
        const map = new google.maps.Map(this.element[0], {
            zoom: 13,
            center: {
                lat: this.lat,
                lng: this.lng
            },
            disableDefaultUI: true,
            backgroundColor: '#dddddd',
            mapTypeControl: false,
            scaleControl: false,
            zoomControl: true,
            scrollwheel: false,
            fullscreenControl: true,
            rotateControl: false,
            tilt: 45,
        });

        const image = {
            url: this.themeUrl + '/dist/images/pointer.svg',
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(20, 28)
        };
        
        const marker = new google.maps.Marker({
            map: map,
            position: {
                lat: this.lat,
                lng: this.lng
            },
            title: this.title,
            icon: image
        });

        marker.setMap(map);
    }
}
