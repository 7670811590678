import 'slick-carousel';
import { BaseWidget } from './base-widget';

export class ReviewsCarousel extends BaseWidget {
    static NAME = 'reviews-carousel';

    constructor(element) {
        super(element);
    }

    init() {
        $('.clients__reviews').slick({
            slidesToShow: 1.1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            infinite: false,
            swipeToSlide: true,
            autoplay: true,
            mobileFirst: true,
            prevArrow: $('.c-carousel__button--prev'),
            nextArrow: $('.c-carousel__button--next'),
            responsive: [
                {
                    breakpoint: 650,
                    settings: {
                      slidesToShow: 2.1,
                      slidesToScroll: 1,
                    }
                }
               
            ]
        });
    }
}