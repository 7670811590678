import { BaseWidget } from './base-widget';

export class Hover extends BaseWidget {
    static NAME = 'hover';

    constructor(element) {
        super(element);
        this.targetId = this.element.data('hover');
        this.targetIds = $(`[data-hover]`);
        this.target = $(`[data-hover-target="${this.targetId}"]`);
        this.targets = $(`[data-hover-target]`);
    }

  init() {

    this.element.on('mouseover', (e) => {
      if ($(window).width() > 768) {
        this.show();
      }
    });

    this.element.on('click', (e) => {
      if ($(window).width() <= 768) {
        this.toggle();
      }
    });
    
  }

    show() {
      $(this.targets).removeClass('is-active');
      $(this.targetIds).removeClass('is-active');
      $(this.element).addClass('is-active');
      $(this.target).addClass('is-active');
    }
  
  toggle() {    
    if ($(this.element).hasClass('is-active')) {
        $(this.targets).removeClass('is-active');
        $(this.targetIds).removeClass('is-active');
      } else {
        $(this.targets).removeClass('is-active');
        $(this.targetIds).removeClass('is-active');
        $(this.element).addClass('is-active');
        $(this.target).addClass('is-active');
      }
    }
}
