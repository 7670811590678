import { BaseWidget } from './base-widget';
const lazysizes = require('lazysizes');

export class Lazyload extends BaseWidget {
    static NAME = 'lazyload';

    constructor(element) {
        super(element);
    }

    init() {
        lazysizes.init();

        if (location.hostname == 'localhost') {
            this.testSrc();
        }
        
        // if ('loading' in HTMLImageElement.prototype) {
        //     const images = document.querySelectorAll("img.lazyload");
        //     images.forEach(img => {
        //         img.src = img.dataset.src;
        //     });
        // } else {
        //     const lazysizes = require('lazysizes');
        // }
    }

    testSrc() {
        // console.log($('picture').prop('currentSrc'));

        function imag(){
            $('img').each(function( index ) {           
                //Old browser
                if ( typeof $(this)[0].currentSrc === "undefined" ) console.log( $(this)[0].src );
    
                //Modern browser
                else console.log( $(this)[0].currentSrc );
            });
            
        }
        
        setTimeout(imag, 10000);
    }
}
